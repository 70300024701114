import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  IconButton,
  makeStyles,
  Theme,
  Drawer,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Hidden,
  Link,
  ListSubheader,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { memo, useEffect, useState } from 'react'
import {
  SWAPLogo,
  Tabs,
  Tab,
  Button,
  Menu,
  MenuItem,
  Typography,
  Modal,
  Container,
  SWAPSpace, SegmentedTabs, SegmentedTab
} from '@yosgo/swap-ui'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MenuIcon from '@material-ui/icons/Menu'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import BusinessIcon from '@material-ui/icons/Business'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import WorkIcon from '@material-ui/icons/Work'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import ReceiptIcon from '@material-ui/icons/Receipt'
import PeopleIcon from '@material-ui/icons/People'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import FacebookIcon from '@material-ui/icons/Facebook'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import FiberNewIcon from '@material-ui/icons/FiberNew'

import { WindowExist } from '../../utils/HandleMode'
import { PathOr } from '../../utils/FunctionsBox'
import { ParseAccountToken, RemoveAccountToken } from '../../utils/HandleToken'
import AuthModal from '../Common/AuthModal'
import BizAuthModal from '../Common/BizAuthModal'
import {
  GetBusiness,
  GetMember,
  GetMemberPartnerBizs,
} from '../../utils/AppRequest'
import QS from '../../utils/QS'
import { FreshChatOpen } from '../Common/FreshChat'
import {
  Product_Name_Invoice,
  Product_Name_SearchEngine,
  Product_Name_Verified,
} from './Pricing'
import { GroupItem } from '../Layouts/group/layout'
import PathAndTitle from '../../utils/PathAndTitle'

/**
 * NavigationProps
 *
 * @type 必填，產品類型 'soho' 自由工作者端，'biz' 企業端
 * @page 必填，頁面類型，'normal' 一般可公開訪問的頁面，'admin' 登入後的頁面, 'public' 透過連結進入的公開頁面
 * @ctaHidden 非必填，是否隱藏導覽列中的 CTA 按鈕
 * @position 非必填，預設 fixed
 */
interface NavigationProps {
  type: 'soho' | 'biz'
  page: 'normal' | 'admin' | 'public'
  groupList?: GroupItem[]
  ctaHidden?: boolean
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative'
  handleOpenUpdatedSidebar?: (isOpen?: boolean) => void
  homePage?: boolean
  noShadow?: boolean
}
interface CustomIconButtonProps {
  onClick: any
  children: React.ReactNode
}

interface MemberProps {
  bio: any
  birth: any
  description: any
  email: string | null
  facebook_insights: any
  facebook_insights_updated_at: any
  features_permissions: any[]
  first_name: any
  gender: any
  id: string
  identity: string
  intentions: any
  is_confirm_wallet: boolean
  is_foreigner: boolean
  is_valid_wallet: boolean
  last_name: any
  milestones: any
  picture: any
  portfolios: any
  social_medias: any
  tags: any
  verified_address: string | null
  verified_bank_code: string | null
  verified_bank_id: string | null
  verified_bank_name: string | null
  verified_comment: string | null
  verified_id_number: string | null
  verified_images: string[] | any
  verified_name: string | null
  verified_phone: string | null
  verified_reject_reson: any
  verified_state: string | null
  wallet: {
    balance: number | any
    created_at: string | any
    is_ban: boolean | any
    updated_at: string | any
    wallet_logs: Object[] | any
    withdraw_balance: number | any
  }
}

const Navigation = ( {type, page, ctaHidden, position, groupList, homePage, noShadow=false} : NavigationProps) => {
  /**Normal */

  const [userType, setUserType]: ['business' | 'member' | undefined, any] =
    useState(undefined)
  const [tabIndex, setTabIndex] = useState(0)
  const [groupTabIndex, setGroupTabIndex] = useState(0)
  const [sohoAuthModal, setSohoAuthModal] = useState(false)
  const [bizAuthModal, setBizAuthModal] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [menu, setMenu] = useState(false)
  // const [submenu, setSubMenu] = useState(false)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  // const [subMenuEl, setSubMenuEl] = useState<null | HTMLElement>(null)
  const [sohoAuthModalType, setSohoAuthModalType]: ['login' | 'register', any] =
    useState('login')
  const [bizAuthModalType, setBizAuthModalType]: ['login' | 'register', any] =
    useState('login')
  const [member, setMember] = useState<MemberProps | undefined>(undefined)
  const [business, setBusiness]: any = useState(undefined)
  const [mobileList, setMobileList] = useState(false)
  const [subMobileList, setSubMobileList] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  /**Admin */
  const [isGetBizpartner, setIsGetBizpartner] = useState(false)
  const [adminMenu, setAdminMenu] = useState(false)
  const [adminMenuEl, setAdminMenuEl] = useState<null | HTMLElement>(null)
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false)
  const [adminDrawerMoreList, setAdminDrawerMoreList] = useState(false)

  /**Shared */
  const [tabAnimation, setTabAnimation] = useState(true)

  const theme = useTheme()
  const xl = useMediaQuery(theme.breakpoints.only('xl'))
  const lg = useMediaQuery(theme.breakpoints.only('lg'))
  const md = useMediaQuery(theme.breakpoints.only('md'))
  const matchMD = useMediaQuery('(min-width:768px)')
  const match1500 = useMediaQuery(`(min-width:1500px)`)
  const match992 = useMediaQuery(`(min-width:992px)`)

  const useStyles = makeStyles((theme: Theme) => ({
    userToolbar: {
      background: '#ffffff',
      minHeight: 56,
      padding: 0,
    },
    menuIcon: {
      backgroundColor: theme.primary.primary50,
      '&:hover': {
        backgroundColor: theme.primary.primary100,
      },
    },
  }))

  const CustomIconButton = ({ onClick, children }: CustomIconButtonProps) => {
    const classes = useStyles()
    return (
      <IconButton
        className={classes.menuIcon}
        style={{
          width: 32,
          height: 32,
          borderRadius: 8,
          marginLeft: 8,
        }}
        onClick={onClick}
      >
        {children}
      </IconButton>
    )
  }

  /**freshchat 客服功能 */
  const handleOpenChatroom = () => {
    FreshChatOpen()
    setAdminMenu(false)
  }

  const navConfigAdapter = {
    soho: {
          landingPageUrl: '/',
          basicTab: [
            {
              text: '產品與功能',
              url: '/',
              menu: [
                {
                  text: '給自由工作者會員',
                  subMenu: [
                    {
                      text: Product_Name_Verified,
                      url: '/functions/verify',
                      icon: <VerifiedUserIcon />,
                    },
                    {
                      text: Product_Name_SearchEngine,
                      url: '/functions/profile',
                      icon: <PeopleIcon />,
                    },
                    {
                      text: Product_Name_Invoice,
                      url: '/functions/invoice',
                      icon: <ReceiptIcon />,
                    },
                  ],
                },
                {
                  text: '給公司企業會員',
                  subMenu: [
                    {
                      text: 'SWAP 企業版',
                      url: '/biz',
                      icon: <BusinessIcon />,
                    },
                    {
                      text: '人才搜尋引擎',
                      url: '/profile',
                      icon: <FindInPageIcon />,
                    },
                  ],
                },
                {
                  text: '社群與媒體',
                  subMenu: [
                    {
                      text: '部落格',
                      url: 'https://blog.swap.work/',
                      icon: <MenuBookIcon />,
                    },
                    {
                      text: '粉絲專頁',
                      url: 'https://www.facebook.com/swapbiz',
                      icon: <FacebookIcon />,
                    },
                  ],
                },
                {
                  text: '小工具',
                  subMenu: [
                    {
                      text: '所得申報類別小幫手',
                      url: '/tools/laborproject',
                      icon: null,
                    },
                    {
                      text: '請款單模擬器',
                      url: '/tools/payment?amt=10000',
                      icon: null,
                    },
                    {
                      text: (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                        >
                          個人所得稅試算機
                          <FiberNewIcon color="primary" />
                        </div>
                      ),
                      url: '/tools/incometax',
                      icon: '',
                    },
                    {
                      text: (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                        >
                          勞報單小工具
                          <FiberNewIcon color="primary" />
                        </div>
                      ),
                      url: '/tools/self-labor-reward-tool',
                      icon: '',
                    },
                  ],
                },
              ],
            },
            {
              text: '收費方案',
              url: '/pricing',
            },
            {
              text: '常見問答',
              url: '/faqs',
            },
            {
              text: '會員專訪',
              url: 'https://blog.swap.work/category/interview/',
            },
            // {
            //   text: 'SWAP 企業版',
            //   url: '/biz',
            //   before: <BusinessIcon />,
            //   divider: true,
            // },
          ],
          primaryAction: {
            text: '註冊帳號',
            onClick: () => {
              setSohoAuthModalType('register')
              setSohoAuthModal(true)
            },
          },
          secondaryAction: {
            text: '登入',
            onClick: () => {
              setSohoAuthModalType('login')
              setSohoAuthModal(true)
            },
          },
          adminUrl: '/member',
          adminTab: [
            {
              text: '帳戶總覽',
              url: '/member',
            },
            {
              text: '帳戶設定',
              url: '/member/settings',
            },
            {
              text: '合作企業',
              url: '/member/bizpartner',
              hidden: !isGetBizpartner,
            },
          ],
          adminMenu: [
            {
              before: '',
              text: '收費方案',
              url: '/pricing',
            },
            {
              before: '',
              text: '常見問答',
              url: '/faqs',
            },
            {
              before: '',
              text: '聯絡我們',
              onClick: handleOpenChatroom,
            },
            // {
            //   before: '',
            //   text: '產品更新',
            //   onClick: () =>
            //     props.handleOpenUpdatedSidebar
            //       ? props.handleOpenUpdatedSidebar()
            //       : () => {},
            // },
            {
              before: '',
              text: '回到 SWAP 官網',
              url: '/',
            },
          ],
          adminList: [
            {
              text: '接案社團',
              url: '/member/group/news',
            },
            {
              text: '帳戶總覽',
              url: '/member',
            },
            {
              text: '我的專案',
              url: '/member/projects',
            },
            {
              text: '我的文件',
              url: '/member/files',
            },
            {
              text: '接案履歷',
              url: '/member/profile',
            },
            {
              text: '我的成就',
              url: '/member/achievements',
            },
            // {
            //   text: '我的報表(Beta)',
            //   url: '/member/accounting',
            // },
            {
              text: '合作企業',
              url: '/member/bizpartner',
              hidden: !isGetBizpartner,
            },
            {
              text: '帳戶設定',
              url: '/member/settings',
              menu: [
                {
                  before: (
                    <svg
                      width="14.66"
                      height="14.66"
                      viewBox="0 0 14.66 14.66"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.99992 11.8C5.33325 11.8 3.85992 10.9466 2.99992 9.66665C3.01992 8.33331 5.66659 7.59998 6.99992 7.59998C8.33325 7.59998 10.9799 8.33331 10.9999 9.66665C10.1399 10.9466 8.66659 11.8 6.99992 11.8ZM6.99992 2.33331C7.53035 2.33331 8.03906 2.54403 8.41413 2.9191C8.78921 3.29417 8.99992 3.80288 8.99992 4.33331C8.99992 4.86375 8.78921 5.37245 8.41413 5.74753C8.03906 6.1226 7.53035 6.33331 6.99992 6.33331C6.46949 6.33331 5.96078 6.1226 5.5857 5.74753C5.21063 5.37245 4.99992 4.86375 4.99992 4.33331C4.99992 3.80288 5.21063 3.29417 5.5857 2.9191C5.96078 2.54403 6.46949 2.33331 6.99992 2.33331ZM6.99992 0.333313C6.12444 0.333313 5.25753 0.505751 4.4487 0.840783C3.63986 1.17581 2.90493 1.66688 2.28587 2.28593C1.03563 3.53618 0.333252 5.23187 0.333252 6.99998C0.333252 8.76809 1.03563 10.4638 2.28587 11.714C2.90493 12.3331 3.63986 12.8241 4.4487 13.1592C5.25753 13.4942 6.12444 13.6666 6.99992 13.6666C8.76803 13.6666 10.4637 12.9643 11.714 11.714C12.9642 10.4638 13.6666 8.76809 13.6666 6.99998C13.6666 3.31331 10.6666 0.333313 6.99992 0.333313Z"
                        fill="#6F6F6F"
                      />
                    </svg>
                  ),
                  text: '我的帳戶',
                  url: '/member/settings',
                },
                {
                  before: (
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.66671 0.666626C1.31309 0.666626 0.973947 0.807102 0.723898 1.05715C0.47385 1.3072 0.333374 1.64634 0.333374 1.99996V4.66663C1.07337 4.66663 1.66671 5.26663 1.66671 5.99996C1.66671 6.35358 1.52623 6.69272 1.27618 6.94277C1.02613 7.19282 0.686996 7.33329 0.333374 7.33329V9.99996C0.333374 10.3536 0.47385 10.6927 0.723898 10.9428C0.973947 11.1928 1.31309 11.3333 1.66671 11.3333H12.3334C12.687 11.3333 13.0261 11.1928 13.2762 10.9428C13.5262 10.6927 13.6667 10.3536 13.6667 9.99996V7.33329C13.3131 7.33329 12.9739 7.19282 12.7239 6.94277C12.4739 6.69272 12.3334 6.35358 12.3334 5.99996C12.3334 5.25996 12.9334 4.66663 13.6667 4.66663V1.99996C13.6667 1.25996 13.0667 0.666626 12.3334 0.666626H1.66671ZM9.33337 2.66663L10.3334 3.66663L4.66671 9.33329L3.66671 8.33329L9.33337 2.66663ZM4.87337 2.69329C5.52671 2.69329 6.05337 3.21996 6.05337 3.87329C6.05337 4.18625 5.92905 4.48639 5.70776 4.70768C5.48647 4.92897 5.18633 5.05329 4.87337 5.05329C4.22004 5.05329 3.69337 4.52663 3.69337 3.87329C3.69337 3.56034 3.8177 3.2602 4.03899 3.03891C4.26028 2.81761 4.56042 2.69329 4.87337 2.69329ZM9.12671 6.94663C9.78004 6.94663 10.3067 7.47329 10.3067 8.12663C10.3067 8.43958 10.1824 8.73972 9.96109 8.96101C9.7398 9.18231 9.43966 9.30662 9.12671 9.30662C8.47337 9.30662 7.94671 8.77996 7.94671 8.12663C7.94671 7.81367 8.07103 7.51353 8.29232 7.29224C8.51361 7.07095 8.81375 6.94663 9.12671 6.94663Z"
                        fill="#6F6F6F"
                      />
                    </svg>
                  ),
                  text: '我的優惠卷',
                  url: '/member/discount',
                },
                {
                  before: (
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.3333 3.33335H1.66659V2.00002H12.3333V3.33335ZM12.3333 10H1.66659V6.00002H12.3333V10ZM12.3333 0.666687H1.66659C0.926585 0.666687 0.333252 1.26002 0.333252 2.00002V10C0.333252 10.3536 0.473728 10.6928 0.723776 10.9428C0.973825 11.1929 1.31296 11.3334 1.66659 11.3334H12.3333C12.6869 11.3334 13.026 11.1929 13.2761 10.9428C13.5261 10.6928 13.6666 10.3536 13.6666 10V2.00002C13.6666 1.26002 13.0666 0.666687 12.3333 0.666687Z"
                        fill="#6F6F6F"
                      />
                    </svg>
                  ),
                  text: '請款手續費紀錄',
                  url: '/member/bills',
                },
                {
                  before: (
                    <svg
                      width="14.66"
                      height="14.66"
                      viewBox="0 0 14.66 14.66"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4733 12.5267L12.5266 13.4734C12.4017 13.5975 12.2327 13.6672 12.0566 13.6672C11.8805 13.6672 11.7115 13.5975 11.5866 13.4734L3.66663 5.56671C3.44983 5.62951 3.22564 5.66314 2.99996 5.66671C2.57549 5.6664 2.15723 5.56478 1.77995 5.37028C1.40267 5.17579 1.07727 4.89404 0.8308 4.54846C0.584332 4.20288 0.423918 3.80346 0.362896 3.38341C0.301875 2.96335 0.342008 2.5348 0.479959 2.13337L2.17329 3.82671L2.52663 3.47337L3.47329 2.52671L3.82663 2.17337L2.13329 0.480041C2.53471 0.34209 2.96327 0.301956 3.38332 0.362978C3.80338 0.424 4.2028 0.584414 4.54838 0.830882C4.89395 1.07735 5.1757 1.40275 5.3702 1.78003C5.5647 2.15732 5.66632 2.57558 5.66663 3.00004C5.66306 3.22572 5.62943 3.44992 5.56663 3.66671L13.4733 11.5867C13.5975 11.7116 13.6672 11.8806 13.6672 12.0567C13.6672 12.2328 13.5975 12.4018 13.4733 12.5267ZM0.526625 11.5867C0.402458 11.7116 0.332764 11.8806 0.332764 12.0567C0.332764 12.2328 0.402458 12.4018 0.526625 12.5267L1.47329 13.4734C1.5982 13.5975 1.76717 13.6672 1.94329 13.6672C2.11942 13.6672 2.28838 13.5975 2.41329 13.4734L6.05996 9.83337L4.17329 7.94671L0.526625 11.5867ZM12.3333 0.333374L9.66663 1.66671V3.00004L8.21996 4.44671L9.55329 5.78004L11 4.33337H12.3333L13.6666 1.66671L12.3333 0.333374Z"
                        fill="#6F6F6F"
                      />
                    </svg>
                  ),
                  text: '我的方案',
                  url: '/member/plan',
                  after:
                    member && member.identity === 'subscribe' ? (
                      <Box
                        borderRadius={4}
                        border={`1px solid ${theme.black.black500}`}
                        width={42}
                        height={24}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        marginLeft="8px"
                        style={{
                          backgroundColor: theme.black.white,
                        }}
                      >
                        <Typography variant="caption1">PRO</Typography>
                      </Box>
                    ) : null,
                },
                {
                  before: (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.16667 0.5C1.24167 0.5 0.5 1.24167 0.5 2.16667V13.8333C0.5 14.7583 1.24167 15.5 2.16667 15.5H13.8333C14.7583 15.5 15.5 14.7583 15.5 13.8333V2.16667C15.5 1.24167 14.7583 0.5 13.8333 0.5H2.16667ZM2.16667 2.16667H13.8333V13.8333H2.16667V2.16667ZM3.83333 3.83333V5.5H12.1667V3.83333H3.83333ZM3.83333 7.16667V8.83333H12.1667V7.16667H3.83333ZM3.83333 10.5V12.1667H9.66667V10.5H3.83333Z"
                        fill="#6F6F6F"
                      />
                    </svg>
                  ),
                  text: '財力證明',
                  url: '/member/withholdingstatement',
                },
              ],
            },
          ],
        },
    biz: {
          landingPageUrl: '/biz',
          basicTab: [
            {
              text: '產品介紹',
              url: '/biz',
            },
            {
              text: '收費方案',
              url: '/biz/pricing',
            },
            {
              text: '常見問題',
              url: '/biz/faqs',
            },
          ],
          primaryAction: {
            text: '註冊帳號',
            onClick: () => {
              setBizAuthModalType('register')
              setBizAuthModal(true)
            },
          },
          secondaryAction: {
            text: '登入',
            onClick: () => {
              setBizAuthModalType('login')
              setBizAuthModal(true)
            },
          },
          adminUrl: '/business/overview',
          adminTab: [],
          adminMenu: [
            {
              before: (
                <DashboardOutlinedIcon
                  style={{ color: theme.black.black700 }}
                  fontSize="small"
                />
              ),
              text: '帳務總覽',
              url: '/business/overview',
            },
            {
              before: (
                <PeopleAltOutlinedIcon
                  style={{ color: theme.black.black700 }}
                  fontSize="small"
                />
              ),
              text: '合作夥伴',
              url: '/business/partners',
            },
            {
              before: (
                <svg
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3333 7.58333C13.3333 6.43333 14.2667 5.5 15.4167 5.5C16.5667 5.5 17.5 6.43333 17.5 7.58333C17.5 8.73333 16.5667 9.66667 15.4167 9.66667C14.2667 9.66667 13.3333 8.73333 13.3333 7.58333ZM10.8333 0.5V14.6667H20V0.5H10.8333ZM18.3333 11.3333C17.4167 11.3333 16.6667 12.0833 16.6667 13H14.1667C14.1667 12.0833 13.425 11.3333 12.5 11.3333V3.83333C13.425 3.83333 14.1667 3.09167 14.1667 2.16667H16.6667C16.6667 3.09167 17.4167 3.83333 18.3333 3.83333V11.3333ZM5.83333 3C6.75 3 7.5 3.75 7.5 4.66667C7.5 5.58333 6.75 6.33333 5.83333 6.33333C4.91667 6.33333 4.16667 5.58333 4.16667 4.66667C4.16667 3.75 4.91667 3 5.83333 3ZM5.83333 1.33333C3.99167 1.33333 2.5 2.825 2.5 4.66667C2.5 6.50833 3.99167 8 5.83333 8C7.675 8 9.16667 6.50833 9.16667 4.66667C9.16667 2.825 7.675 1.33333 5.83333 1.33333ZM5.83333 9.66667C2.60833 9.66667 0 11.1583 0 13V14.6667H9.16667V13H1.66667C1.66667 12.5167 3.125 11.3333 5.83333 11.3333C7.35833 11.3333 8.475 11.7083 9.16667 12.125V10.2667C8.225 9.89167 7.08333 9.66667 5.83333 9.66667Z"
                    fill="#6F6F6F"
                  />
                </svg>
              ),
              text: '匯款群組',
              url: '/business/projects',
            },
            {
              before: (
                <SettingsOutlinedIcon
                  style={{ color: theme.black.black700 }}
                  fontSize="small"
                />
              ),
              text: '帳戶設定',
              url: '/business/settings',
            },
            {
              before: '',
              text: '聯絡我們',
              onClick: handleOpenChatroom,
              divider: true,
            },
            {
              before: '',
              text: '回到 SWAP 企業官網',
              url: '/biz',
            },
          ],
        },
    public: {
      landingPageUrl: '/member',
      basicTab: [],
      primaryAction: {
        text: '馬上註冊',
        onClick: () => {
          setBizAuthModalType('register')
          setBizAuthModal(true)
        },
      },
      secondaryAction: {
        text: '登入',
        onClick: () => {
          setBizAuthModalType('login')
          setBizAuthModal(true)
        },
      },
      adminUrl: '/business/overview',
      adminTab: [],
      adminMenu: [],
    }
  }
  const navConfig: any = navConfigAdapter[type]
  const groupConfig: any = groupList ? [
    { case_group_id: 'my-news', title: '我的動態', avatar: '', url: PathAndTitle.member.group.news.path},
    { case_group_id: 'my-list', title: '社團列表', avatar: '', url: PathAndTitle.member.group.list.path},
    ...groupList.map((item: any) => ({url: PathAndTitle.member.group.detail.path.replace('[id]', item.case_group_id), ...item}))
  ] : []

  useEffect(() => {
    /**判斷使用者類型 */
    if (type === 'soho') {
      const usertype = PathOr(undefined, ['type'], ParseAccountToken())
      setUserType(usertype)
    }
    if (type === 'biz') {
      const usertype = PathOr(undefined, ['type'], ParseAccountToken('biz'))
      setUserType(usertype)
    }
    setTabAnimation(false)
    /**判斷 Normal 頁面，Tab Active 使用 */
    if (WindowExist()) {
      for (let x = 0; x < navConfig.basicTab.length; x++) {
        const isCurrent =
          window.location.pathname.indexOf(navConfig.basicTab[x].url) >= 0
        if (isCurrent) {
          setTabIndex(x)
        }
      }
    } else {
      setTabIndex(0)
    }
    if (WindowExist()) {
      for (let x = 0; x < groupConfig.length; x++) {
        const isCurrent =
          window.location.pathname.indexOf(groupConfig[x].url) >= 0
        if (isCurrent) {
          setGroupTabIndex(x)
        }
      }
    }
    /**判斷第三方 登入/註冊 錯誤狀態，SOHO 才有第三方登入 */
    const authState: any = QS('state', window.location.href)
    const authError: any = QS('error', window.location.href)
    if (authError) {
      if (authState === 'login') {
        setSohoAuthModalType('login')
        setSohoAuthModal(true)
      }
      if (authState === 'register') {
        setSohoAuthModalType('register')
        setSohoAuthModal(true)
      }
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      /**依照目前的使用者類型去查詢 SOHO 或是 Biz 資料 */
      if (userType === 'member' || userType === 'business') {
        const isMember = userType === 'member'
        try {
          const result: any = isMember ? await GetMember() : await GetBusiness()
          if (result && result.errors === null) {
            const data = result.payload[isMember ? `member` : `business`]
            if (isMember) {
              setMember(data)
            } else {
              setBusiness(data)
            }
          }
        } catch (err) {
          alert('無法讀取會員資料，請登出後再重新登入')
        }
      }
      /**查詢用戶是否有合作企業 */
      if (userType === 'member') {
        const GetMemberPartnerBizsresult: any = await GetMemberPartnerBizs()
        if (GetMemberPartnerBizsresult) {
          if (GetMemberPartnerBizsresult.errors === null) {
            if (GetMemberPartnerBizsresult.payload.length > 0) {
              setIsGetBizpartner(true)
            } else {
              setIsGetBizpartner(false)
            }
          } else {
            console.log(GetMemberPartnerBizsresult.errors)
          }
        }
      }
    })()
  }, [userType])

  /**
   * 在首頁時，監聽滾動，改變導航的背景顏色與陰影
   */
  const HeroPageWithoutNavigationHeight = 768 - 56;
  useEffect(() => {
    if (homePage) {
      function scrollFn(): void {
        const newScrollTop =
          document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        setScrollTop(newScrollTop);
      }
      window.addEventListener("scroll", scrollFn);
      return (): void => {
        window.removeEventListener("scroll", scrollFn);
      };
    }
  }, []);

  const handleRouterPush = (href: string) => {
    window.location.href = href
  }

  const handleTabClick = (item: GroupItem) => {
    if (item.case_group_id === 'my-news') {
      window.location.href = `${window.location.origin}${PathAndTitle.member.group.news.path}`
      return
    }
    if (item.case_group_id === 'my-list') {
      window.location.href = `${window.location.origin}${PathAndTitle.member.group.list.path}`
      return
    }
    window.location.href = `${window.location.origin}${PathAndTitle.member.group.detail.path.replace('[id]', item.case_group_id)}`
  }

  return (
    <div>
      <Hidden>
        {/*SOHO 註冊登入 */}
        <AuthModal
          open={sohoAuthModal}
          onClose={() => setSohoAuthModal(false)}
          type={sohoAuthModalType}
        />
        {/*Biz 註冊登入 */}
        <BizAuthModal
          open={bizAuthModal}
          onClose={() => setBizAuthModal(false)}
          type={bizAuthModalType}
        />
        {/**登出 */}
        <Modal
          open={isOpenLogoutModal}
          size="extraSmall"
          title="你確定要登出嗎？"
          onClose={() => setIsOpenLogoutModal(false)}
          primaryButton={{
            title: '登出',
            onClick: () => {
              handleRouterPush(navConfig.landingPageUrl)
              if (userType === 'member') {
                RemoveAccountToken()
              }
              if (userType === 'business') {
                RemoveAccountToken('biz')
              }
            },
          }}
          secondaryButton={{
            title: '取消',
            onClick: () => setIsOpenLogoutModal(false),
          }}
          buttonFullWidth
        />
        {/**手機版 drawer，Biz admin 不會使用到*/}
        {drawer && (
          <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
            <div
              style={{
                width: `calc(100vw - 64px)`,
                padding: '24px 0',
              }}
            >
              <div>
                <div>
                  {(() => {
                    const bizIsLogin = type === 'biz' && userType === 'business'
                    const sohoIsLogin = type === 'soho' && userType === 'member'
                    return (
                      <div>
                        {/**手機版註冊/登入 */}
                        <Box padding="0 24px">
                          <Box display="flex" alignItems="center">
                            {sohoIsLogin ? (
                              <Avatar
                                style={{
                                  width: '48px',
                                  height: '48px',
                                  background: theme.black.black700,
                                }}
                                src={
                                  sohoIsLogin
                                    ? 'https://swap-img.swap.work/media/navigation_login_avatar.svg'
                                    : undefined
                                }
                              />
                            ) : null}
                            {!sohoIsLogin && type === 'soho' ? (
                              <Avatar
                                style={{
                                  width: '48px',
                                  height: '48px',
                                  background: theme.black.black700,
                                }}
                              />
                            ) : null}
                            {bizIsLogin ? (
                              <Box
                                style={{
                                  border: `1px solid ${theme.black.black900}`,
                                  borderRadius: 10,
                                  width: 48,
                                  height: 48,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Box
                                  style={{
                                    backgroundColor: `${theme.black.black900}`,
                                    borderRadius: 8,
                                    width: 40,
                                    height: 40,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography variant="subtitle" color="white">
                                    {
                                      PathOr(
                                        PathOr('', ['email'], business),
                                        ['verified_company_name'],
                                        business
                                      )[0]
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                            ) : null}
                            {!bizIsLogin && type === 'biz' ? (
                              <Avatar
                                style={{
                                  width: '48px',
                                  height: '48px',
                                  background: theme.black.black700,
                                }}
                              >
                                <WorkIcon />
                              </Avatar>
                            ) : null}
                            <Box marginLeft="16px">
                              <Typography variant="body1">
                                {sohoIsLogin ? (
                                  <Typography variant="body1">
                                    歡迎回來
                                    {member && member.verified_name ? (
                                      <b>，{member.verified_name}！</b>
                                    ) : (
                                      ''
                                    )}
                                  </Typography>
                                ) : null}
                                {bizIsLogin ? (
                                  <Typography variant="body1">
                                    {business && business.verified_company_name
                                      ? business.verified_company_name
                                      : '歡迎使用 SWAP 企業版'}
                                  </Typography>
                                ) : null}
                                {!bizIsLogin && !sohoIsLogin ? (
                                  <Typography variant="body1">
                                    <b>成為 SWAP 會員吧！</b>
                                  </Typography>
                                ) : null}
                              </Typography>
                              <Box
                                marginTop="4px"
                                onClick={() => {
                                  if (sohoIsLogin) {
                                    window.location.href = navConfig.adminUrl
                                  } else {
                                    setDrawer(false)
                                    setTimeout(() => {
                                      type === 'soho'
                                        ? setSohoAuthModal(true)
                                        : setBizAuthModal(true)
                                    }, 250)
                                  }
                                }}
                              >
                                {page === 'normal' ? (
                                  <Typography
                                    color="primary400"
                                    variant="body1"
                                  >
                                    {!ctaHidden ? (
                                      <b>
                                        {sohoIsLogin || bizIsLogin
                                          ? '前往帳戶總覽'
                                          : '註冊帳號/登入'}
                                      </b>
                                    ) : null}
                                  </Typography>
                                ) : null}
                                {page === 'admin' ? (
                                  <Typography color="black700" variant="body2">
                                    {PathOr('', ['email'], member)}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {/**手機版分隔線 */}
                        <Box padding="0 24px">
                          <Box
                            marginTop="26px"
                            marginBottom="16px"
                            height="1px"
                            bgcolor={theme.black.black400}
                          />
                        </Box>
                        {/*Drawer list */}
                        <List>
                          {navConfig[
                            `${page === 'admin' ? 'adminList' : 'basicTab'}`
                          ]?.map((item: any, index: number) =>
                            item.hidden ? null : (
                              <div key={`mobile_${item.text}_${index}`}>
                                {/**上方分隔線 */}
                                {item.divider ? (
                                  <Box
                                    bgcolor={theme.black.black400}
                                    height="1px"
                                    margin="8px 24px"
                                  />
                                ) : null}{' '}
                                <ListItem
                                  button
                                  disableGutters
                                  dense
                                  onClick={() => {
                                    if (item.menu) {
                                      setMobileList(!mobileList)
                                    } else {
                                      handleRouterPush(item.url)
                                    }
                                  }}
                                  style={{
                                    paddingRight: '24px',
                                    paddingLeft: '24px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {/**前綴 */}
                                    {item.before ? (
                                      <Box
                                        marginRight="8px"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        {item.before}
                                      </Box>
                                    ) : null}
                                    <ListItemText
                                      primary={
                                        <Typography variant="h6">
                                          {item.text}
                                        </Typography>
                                      }
                                    />
                                  </div>
                                  {/**子選單 Expand Icon */}
                                  {item.menu ? (
                                    mobileList ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )
                                  ) : null}
                                </ListItem>
                                {/**子選單 */}
                                {item.menu ? (
                                  <Collapse
                                    in={mobileList}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <List>
                                      {type === 'soho' && page === 'normal'
                                        ? item.menu.map(
                                            (menu: any, menuIndex: number) => (
                                              <div
                                                style={{ padding: '0 24px' }}
                                                key={`mobile_sub_${menuIndex}`}
                                              >
                                                <ListSubheader
                                                  disableGutters
                                                  disableSticky
                                                  style={{
                                                    fontWeight: 'normal',
                                                    color: 'black',
                                                  }}
                                                >
                                                  {menu.text}
                                                </ListSubheader>
                                                {menu.subMenu.map(
                                                  (
                                                    sub_item: any,
                                                    sub_index: number
                                                  ) => (
                                                    <div
                                                      key={`mobile_sub_${sub_item.text}_${sub_index}`}
                                                    >
                                                      <ListItem
                                                        button
                                                        disableGutters
                                                        dense
                                                        onClick={() => {
                                                          if (sub_item.menu) {
                                                            setSubMobileList(
                                                              !subMobileList
                                                            )
                                                          } else {
                                                            handleRouterPush(
                                                              sub_item.url
                                                            )
                                                          }
                                                        }}
                                                        style={{
                                                          paddingRight: '24px',
                                                          paddingLeft: '24px',
                                                        }}
                                                      >
                                                        {/**標籤名稱 */}
                                                        <ListItemText
                                                          primary={
                                                            <Box
                                                              display="flex"
                                                              alignItems="center"
                                                            >
                                                              {/**前綴 */}
                                                              {sub_item.before
                                                                ? sub_item.before
                                                                : null}
                                                              <Typography
                                                                variant="subtitle"
                                                                style={{
                                                                  marginLeft:
                                                                    '9.67px',
                                                                }}
                                                              >
                                                                {sub_item.text}
                                                              </Typography>
                                                              {/**後綴 */}
                                                              {sub_item.after
                                                                ? sub_item.after
                                                                : null}
                                                            </Box>
                                                          }
                                                        />
                                                        {/**子選單 */}
                                                        {sub_item.menu ? (
                                                          subMobileList ? (
                                                            <ExpandLess />
                                                          ) : (
                                                            <ExpandMoreIcon />
                                                          )
                                                        ) : null}
                                                      </ListItem>
                                                      {/**子選單中的子選單 */}
                                                      <div>
                                                        {sub_item.menu ? (
                                                          <Collapse
                                                            in={subMobileList}
                                                            timeout="auto"
                                                            unmountOnExit
                                                          >
                                                            <List
                                                              component="div"
                                                              disablePadding
                                                            >
                                                              {sub_item.menu.map(
                                                                (
                                                                  sub_sub_item: any,
                                                                  sub_sub_index: number
                                                                ) => (
                                                                  <ListItem
                                                                    key={`mobile_${sub_sub_item}_${sub_sub_index}`}
                                                                    button
                                                                    onClick={() => {
                                                                      handleRouterPush(
                                                                        sub_sub_item.url
                                                                      )
                                                                    }}
                                                                    style={{
                                                                      paddingRight:
                                                                        '32px',
                                                                      paddingLeft:
                                                                        '32px',
                                                                    }}
                                                                  >
                                                                    <ListItemText
                                                                      primary={
                                                                        <Typography variant="body2">
                                                                          {
                                                                            sub_sub_item.text
                                                                          }
                                                                        </Typography>
                                                                      }
                                                                    />
                                                                  </ListItem>
                                                                )
                                                              )}
                                                            </List>
                                                          </Collapse>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )
                                          )
                                        : item.menu.map(
                                            (
                                              sub_item: any,
                                              sub_index: number
                                            ) => (
                                              <div
                                                key={`mobile_sub_${sub_item.text}_${sub_index}`}
                                              >
                                                <ListItem
                                                  button
                                                  disableGutters
                                                  dense
                                                  onClick={() => {
                                                    if (sub_item.menu) {
                                                      setSubMobileList(
                                                        !subMobileList
                                                      )
                                                    } else {
                                                      handleRouterPush(
                                                        sub_item.url
                                                      )
                                                    }
                                                  }}
                                                  style={{
                                                    paddingRight: '24px',
                                                    paddingLeft: '24px',
                                                  }}
                                                >
                                                  {/**標籤名稱 */}
                                                  <ListItemText
                                                    primary={
                                                      <Box
                                                        display="flex"
                                                        alignItems="center"
                                                      >
                                                        {/**前綴 */}
                                                        {sub_item.before
                                                          ? sub_item.before
                                                          : null}
                                                        <Typography
                                                          variant="subtitle"
                                                          style={{
                                                            marginLeft:
                                                              '9.67px',
                                                          }}
                                                        >
                                                          {sub_item.text}
                                                        </Typography>
                                                        {/**後綴 */}
                                                        {sub_item.after
                                                          ? sub_item.after
                                                          : null}
                                                      </Box>
                                                    }
                                                  />
                                                  {/**子選單 */}
                                                  {sub_item.menu ? (
                                                    subMobileList ? (
                                                      <ExpandLess />
                                                    ) : (
                                                      <ExpandMoreIcon />
                                                    )
                                                  ) : null}
                                                </ListItem>
                                                {/**子選單中的子選單 */}
                                                <div>
                                                  {sub_item.menu ? (
                                                    <Collapse
                                                      in={subMobileList}
                                                      timeout="auto"
                                                      unmountOnExit
                                                    >
                                                      <List
                                                        component="div"
                                                        disablePadding
                                                      >
                                                        {sub_item.menu.map(
                                                          (
                                                            sub_sub_item: any,
                                                            sub_sub_index: number
                                                          ) => (
                                                            <ListItem
                                                              key={`mobile_${sub_sub_item}_${sub_sub_index}`}
                                                              button
                                                              onClick={() => {
                                                                handleRouterPush(
                                                                  sub_sub_item.url
                                                                )
                                                              }}
                                                              style={{
                                                                paddingRight:
                                                                  '32px',
                                                                paddingLeft:
                                                                  '32px',
                                                              }}
                                                            >
                                                              <ListItemText
                                                                primary={
                                                                  <Typography variant="body2">
                                                                    {
                                                                      sub_sub_item.text
                                                                    }
                                                                  </Typography>
                                                                }
                                                              />
                                                            </ListItem>
                                                          )
                                                        )}
                                                      </List>
                                                    </Collapse>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )
                                          )}
                                    </List>
                                  </Collapse>
                                ) : null}
                              </div>
                            )
                          )}
                          {/**SOHO 在 admin 時的額外 list 選項 */}
                          {type === 'soho' && page === 'admin' ? (
                            <div>
                              <Box
                                bgcolor={theme.black.black400}
                                height="1px"
                                margin="8px 24px"
                              />
                              <ListItem
                                button
                                disableGutters
                                dense
                                style={{
                                  paddingRight: '24px',
                                  paddingLeft: '24px',
                                }}
                                onClick={() =>
                                  setAdminDrawerMoreList(!adminDrawerMoreList)
                                }
                              >
                                <ListItemText
                                  primary={
                                    <Typography variant="h6">更多</Typography>
                                  }
                                />
                                {adminDrawerMoreList ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                              <Collapse
                                in={adminDrawerMoreList}
                                timeout="auto"
                                unmountOnExit
                              >
                                {[
                                  {
                                    text: '收費方案',
                                    url: '/pricing',
                                  },
                                  {
                                    text: '常見問答',
                                    url: '/faqs',
                                  },
                                  {
                                    text: '聯絡我們',
                                    onClick: handleOpenChatroom,
                                  },
                                  {
                                    text: '回到 SWAP 官網',
                                    url: '/',
                                  },
                                ].map((item, index) => (
                                  <List
                                    key={`soho_admin_more_${index}`}
                                    style={{
                                      padding: '0 24px',
                                    }}
                                  >
                                    <ListItem
                                      button
                                      disableGutters
                                      dense
                                      onClick={
                                        item.url
                                          ? () => {
                                              handleRouterPush(item.url)
                                            }
                                          : item.onClick
                                          ? item.onClick
                                          : () => {}
                                      }
                                    >
                                      <ListItemText
                                        primary={
                                          <Typography variant="subtitle">
                                            {item.text}
                                          </Typography>
                                        }
                                      />
                                    </ListItem>
                                  </List>
                                ))}
                              </Collapse>
                              <ListItem
                                button
                                disableGutters
                                dense
                                style={{
                                  paddingRight: '24px',
                                  paddingLeft: '24px',
                                }}
                                onClick={() => {
                                  setDrawer(false)
                                  setTimeout(() => {
                                    setIsOpenLogoutModal(true)
                                  }, 100)
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Typography variant="h6" color="black800">
                                      登出帳號
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </div>
                          ) : null}
                        </List>
                      </div>
                    )
                  })()}
                </div>
              </div>
            </div>
          </Drawer>
        )}
        {/*Navigation */}
        <AppBar
          id="navigation"
          position={position}
          style={{
            left:
              page === 'admin'
                ? match1500
                  ? 200
                  : match992
                  ? 72
                  : type === 'soho'
                  ? 0
                  : 72
                : undefined,
            width:
              page === 'admin'
                ? `calc(100% - ${
                    match1500 ? 200 : match992 ? 72 : type === 'soho' ? 0 : 72
                  }px)`
                : undefined,
                boxShadow: homePage && (scrollTop < HeroPageWithoutNavigationHeight)
                ? 'unset'
                : noShadow
                  ? 'none'
                  : '0px 2px 16px rgba(0, 0, 0, 0.08)',
            zIndex: 1090,
            border: '0px solid white',
            backgroundColor: 'white',
          }}
        >
          <Toolbar style={{
            background: (homePage && scrollTop < HeroPageWithoutNavigationHeight) ? '#E6E9F8' : '#ffffff',
            minHeight: 56,
            padding: 0
          }}>
            <Container
              // maxWidth="xl"
              style={
                type === 'biz' && page === 'admin'
                  ? {
                      maxWidth: '100%',
                      paddingLeft: '22px',
                      paddingRight: '24px',
                    }
                  : {
                      padding: matchMD ? '0px 24px' : '0px 16px',
                      maxWidth: '100%',
                    }
              }
            >
              {groupConfig.length && !match992 ? (
                <SWAPSpace size={8} />
              ) : null}
              {/** Normal */}
              {page === 'normal' ? (
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <a href={navConfig.landingPageUrl}>
                        {type === 'soho' ? <SWAPLogo /> : null}
                        {type === 'biz' ? (
                          <SWAPLogo size="small" business />
                        ) : null}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item>
                    <div>
                      {/** 電腦版 */}
                      {xl || lg || md ? (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center">
                            <Tabs value={tabIndex}>
                              {navConfig.basicTab
                                .filter((i: any) => i.text !== 'SWAP 企業版')
                                .map((item: any, index: number) => (
                                  <Tab
                                    key={`menu_${index}`}
                                    value={index}
                                    width={item.menu ? 'auto' : 70}
                                    animation={tabAnimation}
                                    label={
                                      <Box display="flex" alignItems="center">
                                        {item.text}
                                        {item.menu ? (
                                          menu ? (
                                            <ExpandLess />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )
                                        ) : null}
                                      </Box>
                                    }
                                    onClick={(event: any) => {
                                      if (item.menu) {
                                        setMenuEl(event.currentTarget)
                                        setMenu(true)
                                      } else {
                                        handleRouterPush(item.url)
                                      }
                                    }}
                                  />
                                ))}
                              {navConfig.basicTab.map(
                                (item: any, index: number) => (
                                  <div key={`menu_popover_${index}`}>
                                    {item.menu ? (
                                      <Menu
                                        style={{
                                          marginTop: '48px',
                                        }}
                                        anchorEl={menuEl}
                                        id={`${item.text}_menu`}
                                        open={menu}
                                        onClose={() => setMenu(false)}
                                        autoFocus={false}
                                        disableAutoFocusItem
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                        }}
                                      >
                                        <div style={{ padding: '12px 24px' }}>
                                          <Grid container spacing={3}>
                                            {item.menu.map(
                                              (sub: any, subIndex: any) => (
                                                <Grid
                                                  item
                                                  key={`sub_${subIndex}`}
                                                >
                                                  <Typography
                                                    variant="caption1"
                                                    color="black800"
                                                  >
                                                    {sub.text}
                                                  </Typography>
                                                  <SWAPSpace />
                                                  {sub.subMenu.map(
                                                    (
                                                      subMenu: any,
                                                      subMenuIndex: any
                                                    ) => (
                                                      <Link
                                                        key={`subMenuIndex_${subMenuIndex}`}
                                                        href={subMenu.url}
                                                        target="_blank"
                                                        color="inherit"
                                                        underline="none"
                                                      >
                                                        <MenuItem
                                                          style={{
                                                            borderRadius: 4,
                                                            marginBottom:
                                                              sub.subMenu
                                                                .length -
                                                                1 ===
                                                              subMenuIndex
                                                                ? 0
                                                                : 9,
                                                            marginLeft: -16,
                                                          }}
                                                        >
                                                          <Grid
                                                            container
                                                            alignItems="center"
                                                          >
                                                            <Grid
                                                              item
                                                              style={{
                                                                zoom: '0.8',
                                                                marginRight: 8,
                                                                paddingTop: 4,
                                                              }}
                                                            >
                                                              {subMenu.icon}
                                                            </Grid>
                                                            <Grid item>
                                                              {subMenu.text}
                                                            </Grid>
                                                          </Grid>
                                                        </MenuItem>
                                                      </Link>
                                                    )
                                                  )}
                                                </Grid>
                                              )
                                            )}
                                          </Grid>
                                        </div>
                                      </Menu>
                                    ) : null}
                                  </div>
                                  // <div
                                  //   key={`menu_popover_${index}`}
                                  // >
                                  //   {item.menu ? (
                                  //     <Menu
                                  //       style={{
                                  //         marginTop: '60px',
                                  //       }}
                                  //       anchorEl={menuEl}
                                  //       id={`${item.text}_menu`}
                                  //       open={menu}
                                  //       onClose={() => setMenu(false)}
                                  //       autoFocus={false}
                                  //       disableAutoFocusItem
                                  //       anchorOrigin={{
                                  //         vertical: 'bottom',
                                  //         horizontal: 'right',
                                  //       }}
                                  //       transformOrigin={{
                                  //         vertical: 'top',
                                  //         horizontal: 'right',
                                  //       }}
                                  //     >
                                  //       {item.menu.map(
                                  //         (
                                  //           sub_item: any,
                                  //           sub_index: number
                                  //         ) => {
                                  //           return (
                                  //             <div
                                  //               key={`menu_${sub_item.text}_${sub_index}`}
                                  //             >
                                  //               <div>
                                  //                 {/**上方分隔線 */}
                                  //                 {sub_item.divider ? (
                                  //                   <Box
                                  //                     bgcolor={
                                  //                       theme.black.black400
                                  //                     }
                                  //                     height="1px"
                                  //                     margin="8px 0"
                                  //                   />
                                  //                 ) : null}
                                  //               </div>
                                  //               <MenuItem
                                  //                 onClick={(event) => {
                                  //                   if (sub_item.menu) {
                                  //                     setSubMenuEl(
                                  //                       event.currentTarget
                                  //                     )
                                  //                     setSubMenu(true)
                                  //                   } else {
                                  //                     handleRouterPush(
                                  //                       sub_item.url
                                  //                     )
                                  //                   }
                                  //                 }}
                                  //               >
                                  //                 <Box
                                  //                   display="flex"
                                  //                   alignItems="center"
                                  //                 >
                                  //                   {/**前綴 */}
                                  //                   {sub_item.before
                                  //                     ? sub_item.before
                                  //                     : null}
                                  //                   {/**標籤名稱 */}
                                  //                   {sub_item.text}
                                  //                   {/**後綴 */}
                                  //                   {sub_item.after
                                  //                     ? sub_item.after
                                  //                     : null}
                                  //                   {/**子選單 */}
                                  //                   {sub_item.menu ? (
                                  //                     <ChevronRightIcon />
                                  //                   ) : null}
                                  //                 </Box>
                                  //               </MenuItem>
                                  //               {/**子選單中的子選單 */}
                                  //               <div>
                                  //                 {sub_item.menu ? (
                                  //                   <Menu
                                  //                     anchorEl={subMenuEl}
                                  //                     id={`${item.text}_menu`}
                                  //                     open={submenu}
                                  //                     onClose={() =>
                                  //                       setSubMenu(false)
                                  //                     }
                                  //                     disableAutoFocusItem
                                  //                     autoFocus={false}
                                  //                     PaperProps={{
                                  //                       style: {
                                  //                         transform: `translateX(${
                                  //                           240 + 8
                                  //                         }px) translateY(0px)`,
                                  //                       },
                                  //                     }}
                                  //                   >
                                  //                     {sub_item.menu.map(
                                  //                       (
                                  //                         sub_sub_item: any,
                                  //                         sub_sub_index: number
                                  //                       ) => (
                                  //                         <MenuItem
                                  //                           key={`${sub_sub_item.text}_${sub_sub_index}`}
                                  //                           onClick={() => {
                                  //                             handleRouterPush(
                                  //                               sub_sub_item.url
                                  //                             )
                                  //                           }}
                                  //                         >
                                  //                           {sub_sub_item.text}
                                  //                         </MenuItem>
                                  //                       )
                                  //                     )}
                                  //                   </Menu>
                                  //                 ) : null}
                                  //               </div>
                                  //             </div>
                                  //           )
                                  //         }
                                  //       )}
                                  //     </Menu>
                                  //   ) : null}
                                  // </div>
                                )
                              )}
                            </Tabs>
                            {ctaHidden ? null : (
                              <div>
                                {/** SOHO 會員已登入且瀏覽 SOHO 網頁時才顯示，前往帳戶總覽 */}
                                {(type === 'biz' && userType === 'business') ||
                                (type === 'soho' && userType === 'member') ||
                                (type === 'soho' &&
                                  window.location.href.includes(
                                    '/partner-invite/'
                                  )) ? (
                                  <Button
                                    size="small"
                                    variant="primary"
                                    style={{ marginLeft: 12, marginRight: 8 }}
                                    onClick={() => {
                                      window.location.href = navConfig.adminUrl
                                    }}
                                  >
                                    前往帳戶總覽
                                  </Button>
                                ) : (
                                  <Box display="flex" alignItems="center">
                                    <Button
                                      size="small"
                                      variant="primary"
                                      style={{
                                        marginLeft: 12,
                                        marginRight: 8,
                                      }}
                                      onClick={() =>
                                        navConfig.primaryAction.onClick()
                                      }
                                    >
                                      {navConfig.primaryAction.text}
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="secondary"
                                      onClick={() =>
                                        navConfig.secondaryAction.onClick()
                                      }
                                    >
                                      {navConfig.secondaryAction.text}
                                    </Button>
                                  </Box>
                                )}
                              </div>
                            )}
                          </Box>
                        </Box>
                      ) : null}
                      {/** 手機版 */}
                      {!xl && !lg && !md ? (
                        <CustomIconButton onClick={() => setDrawer(true)}>
                          <MenuIcon color="primary" fontSize="small" />
                        </CustomIconButton>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {/** Admin  */}
              {page === 'admin' ? (
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Grid container alignItems="center">
                      {/**Admin Left */}
                      <Box>
                        <a
                          onClick={() => {
                            handleRouterPush(navConfig.adminUrl)
                          }}
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                        >
                          {type === 'soho' ? (
                            <MemberAvatarBlock memberData={member} />
                          ) : null}
                          {type === 'biz' ? (
                            <Box
                              style={{
                                border: `1px solid ${theme.black.black900}`,
                                borderRadius: 10,
                                width: 36,
                                height: 36,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Box
                                style={{
                                  backgroundColor: theme.black.black900,
                                  borderRadius: 8,
                                  width: 32,
                                  height: 32,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography variant="subtitle" color="white">
                                  {
                                    PathOr(
                                      PathOr('', ['email'], business),
                                      ['verified_company_name'],
                                      business
                                    )[0]
                                  }
                                </Typography>
                              </Box>
                            </Box>
                          ) : null}
                        </a>
                      </Box>
                      {/* {type === 'soho' && (xl || lg || md) ? (
                        <Box style={{ marginLeft: '40px' }}>
                          <Tabs value={adminTabIndex}>
                            {navConfig.adminTab.map(
                              (item: any, index: number) =>
                                item.hidden ? null : (
                                  <Tab
                                    key={`menu_${index}`}
                                    value={index}
                                    animation={tabAnimation}
                                    label={item.text}
                                    onClick={() => {
                                      window.location.href = item.url
                                    }}
                                  />
                                )
                            )}
                          </Tabs>
                        </Box>
                      ) : null} */}
                      {type === 'biz' && business ? (
                        <Box style={{ marginLeft: '10px' }}>
                          <Typography
                            variant="caption1"
                            style={{ fontWeight: 700 }}
                          >
                            {PathOr(
                              'SWAP 企業會員您好',
                              ['verified_company_name'],
                              business
                            )}
                          </Typography>
                          <Typography
                            variant="small1"
                            style={{ fontWeight: 400 }}
                          >
                            {PathOr('', ['email'], business)}
                          </Typography>
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                  {/**Admin Right */}
                  <Grid item>
                    <Grid container alignItems="center">
                      {type === 'soho' && member && (xl || lg) ? (
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2" color="black1000">
                            歡迎回來{' '}
                            {member.verified_name !== null ? '，' : '！'}
                          </Typography>
                          {member.verified_name !== null ? (
                            <Typography variant="subtitle" color="black1000">
                              {member.verified_name} ！
                            </Typography>
                          ) : null}
                        </Box>
                      ) : null}
                      <Box
                        onClick={(event) => {
                          setAdminMenuEl(event.currentTarget)
                        }}
                      >
                        <CustomIconButton
                          onClick={() => {
                            if (
                              (type === 'soho' && (xl || lg)) ||
                              type === 'biz'
                            ) {
                              setAdminMenu(!adminMenu)
                            } else {
                              setDrawer(true)
                            }
                          }}
                        >
                          <MenuIcon color="primary" fontSize="small" />
                        </CustomIconButton>
                        {/**SOHO, Biz 電腦版 */}
                        {(type === 'soho' && (xl || lg)) || type === 'biz' ? (
                          <Menu
                            getContentAnchorEl={null}
                            anchorEl={adminMenuEl}
                            id={`admin_menu`}
                            open={adminMenu}
                            onClose={() => setAdminMenu(false)}
                            autoFocus={false}
                            disableAutoFocusItem
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            PaperProps={{
                              style: {
                                transform: `translateX(0px) translateY(10px)`,
                              },
                            }}
                          >
                            {navConfig.adminMenu.map(
                              (item: any, index: number) => (
                                <div
                                  key={`admin_menu-${index}`}
                                  style={{ margin: '2px 0' }}
                                >
                                  {/**上方分隔線 */}
                                  {item.divider ? (
                                    <Box
                                      bgcolor={theme.black.black400}
                                      height="1px"
                                      margin="8px 0"
                                    />
                                  ) : null}
                                  <MenuItem
                                    width={200}
                                    onClick={
                                      item.url
                                        ? () => {
                                            handleRouterPush(item.url)
                                          }
                                        : item.onClick
                                        ? item.onClick
                                        : () => {}
                                    }
                                  >
                                    <Box display="flex" alignItems="center">
                                      {/**前綴 */}
                                      {item.before ? (
                                        <Box
                                          marginRight="8px"
                                          display="flex"
                                          alignItems="center"
                                        >
                                          {item.before}
                                        </Box>
                                      ) : null}
                                      {/**標籤名稱 */}
                                      <Typography
                                        variant="subtitle"
                                        style={{ fontWeight: 700 }}
                                      >
                                        {item.text}
                                      </Typography>
                                      {/**後綴 */}
                                      {item.after ? item.after : null}
                                    </Box>
                                  </MenuItem>
                                </div>
                              )
                            )}
                            <div>
                              <Box
                                bgcolor={theme.black.black400}
                                height="1px"
                                margin="8px 0"
                              />
                              <MenuItem
                                width={200}
                                onClick={() => {
                                  setAdminMenu(false)
                                  setTimeout(() => {
                                    setIsOpenLogoutModal(true)
                                  }, 100)
                                }}
                              >
                                <Typography
                                  variant="subtitle"
                                  style={{
                                    fontWeight: 700,
                                    color: `${theme.black.black800}`,
                                  }}
                                >
                                  登出帳號
                                </Typography>
                              </MenuItem>
                            </div>
                          </Menu>
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {/* Public */}
              {page === 'public' ? (
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <a href={navConfig.landingPageUrl}>
                        {type === 'soho' ? <SWAPLogo /> : null}
                        {type === 'biz' ? (
                          <SWAPLogo size="small" business />
                        ) : null}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item>
                    <div>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center">
                          <Tabs value={tabIndex}>
                            {navConfig.basicTab.map(
                              (item: any, index: number) => (
                                <div key={`menu_popover_${index}`}>
                                  {item.menu ? (
                                    <Menu
                                      style={{
                                        marginTop: '48px',
                                      }}
                                      anchorEl={menuEl}
                                      id={`${item.text}_menu`}
                                      open={menu}
                                      onClose={() => setMenu(false)}
                                      autoFocus={false}
                                      disableAutoFocusItem
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                    >
                                      <div style={{ padding: '12px 24px' }}>
                                        <Grid container spacing={3}>
                                          {item.menu.map(
                                            (sub: any, subIndex: any) => (
                                              <Grid
                                                item
                                                key={`sub_${subIndex}`}
                                              >
                                                <Typography
                                                  variant="caption1"
                                                  color="black800"
                                                >
                                                  {sub.text}
                                                </Typography>
                                                <SWAPSpace />
                                                {sub.subMenu.map(
                                                  (
                                                    subMenu: any,
                                                    subMenuIndex: any
                                                  ) => (
                                                    <Link
                                                      key={`subMenuIndex_${subMenuIndex}`}
                                                      href={subMenu.url}
                                                      target="_blank"
                                                      color="inherit"
                                                      underline="none"
                                                    >
                                                      <MenuItem
                                                        style={{
                                                          borderRadius: 4,
                                                          marginBottom:
                                                            sub.subMenu
                                                              .length -
                                                            1 ===
                                                            subMenuIndex
                                                              ? 0
                                                              : 9,
                                                          marginLeft: -16,
                                                        }}
                                                      >
                                                        <Grid
                                                          container
                                                          alignItems="center"
                                                        >
                                                          <Grid
                                                            item
                                                            style={{
                                                              zoom: '0.8',
                                                              marginRight: 8,
                                                              paddingTop: 4,
                                                            }}
                                                          >
                                                            {subMenu.icon}
                                                          </Grid>
                                                          <Grid item>
                                                            {subMenu.text}
                                                          </Grid>
                                                        </Grid>
                                                      </MenuItem>
                                                    </Link>
                                                  )
                                                )}
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </div>
                                    </Menu>
                                  ) : null}
                                </div>
                              )
                            )}
                          </Tabs>
                          {ctaHidden ? null : (
                            <div>
                              {/** SOHO 會員已登入且瀏覽 SOHO 網頁時才顯示，前往帳戶總覽 */}
                              {(type === 'biz' && userType === 'business') ||
                              (type === 'soho' && userType === 'member') ||
                              (type === 'soho' && WindowExist() &&
                                window.location.href.includes(
                                  '/partner-invite/'
                                )) ? (
                                <Button
                                  size="small"
                                  variant="primary"
                                  style={{ marginLeft: 12, marginRight: 8 }}
                                  onClick={() => {
                                    window.location.href = navConfig.adminUrl
                                  }}
                                >
                                  前往帳戶總覽
                                </Button>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  <Button
                                    size="small"
                                    variant="primary"
                                    style={{
                                      marginLeft: 12,
                                      marginRight: 8,
                                    }}
                                    onClick={() =>
                                      navConfig.primaryAction.onClick()
                                    }
                                  >
                                    {navConfig.primaryAction.text}
                                  </Button>
                                  <Button
                                    size="small"
                                    variant="secondary"
                                    onClick={() =>
                                      navConfig.secondaryAction.onClick()
                                    }
                                  >
                                    {navConfig.secondaryAction.text}
                                  </Button>
                                </Box>
                              )}
                            </div>
                          )}
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {groupConfig.length && !match992 ? (
                <>
                  <SWAPSpace size={8} />
                  <SegmentedTabs
                    value={groupTabIndex}
                    scrollButtons="on"
                    variant="scrollable"
                    style={{
                      display: 'inline-flex',
                      maxWidth: '100%',
                      minHeight: matchMD? 50 : 42,
                      color: '#6F6F6F'
                  }}
                  >
                    {/* 在資料塞入我的動態、社團列表按鈕 */}
                    {groupConfig.map((item: GroupItem, index: number) => (
                      <SegmentedTab
                        key={item.case_group_id}
                        value={index}
                        style={{
                          maxWidth: 120,
                          padding: matchMD ? '10px 16px' : '6px 16px',
                          margin: '0 2px',
                          minHeight: matchMD ? 40 : 32
                      }}
                        label={
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', maxWidth: 88 }}>
                          {item.avatar ? <img src={item.avatar} alt='' style={{ width: 16, height: 16, borderRadius: 4, marginRight: 4 }} /> : null}
                          <Typography
                            variant={matchMD ? 'button_s' : 'caption1'}
                            color={index === groupTabIndex ? 'primary500' : 'black700'}
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {item.title}
                          </Typography>
                        </div>
                        }
                        onClick={() => handleTabClick(item)}
                      />
                    ))}
                  </SegmentedTabs>
                </>
              ) : null}
              {groupConfig.length && !match992 ? (
                <SWAPSpace size={8} />
              ) : null}
            </Container>
          </Toolbar>
        </AppBar>
      </Hidden>
    </div>
  )
}

const MemberAvatarBlock = ({
  memberData,
}: {
  memberData: MemberProps | undefined
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        maxHeight: 36,
        height: 36,
      }}
    >
      <div
        style={{
          height: 36,
          width: 36,
          border: ' 1px solid #2D2D2D',
          borderRadius: 10,
          backgroundColor: '#FFFFFF',
          padding: 1,
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: 8,
            backgroundColor: '#2D2D2D',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="subtitle" style={{ color: '#FFFFFF' }}>
            {memberData?.verified_name
              ? memberData.verified_name[0].toLocaleUpperCase()
              : memberData?.email
              ? memberData.email[0].toLocaleUpperCase()
              : '-'}
          </Typography>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginLeft: 10,
        }}
      >
        <Typography variant="caption1">{memberData?.verified_name}</Typography>
        <Typography variant="small2" color="black800">
          {memberData?.email}
        </Typography>
      </div>
    </div>
  )
}

export default memo(Navigation)
